import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ApolloProvider } from "@apollo/react-hooks";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import MainLayout from "./layouts/Main/Main";
import CreditApplication from "./form/CreditApplicationFormWrapper";
import PaymentSelector from "./payment-selector/PaymentSelector";
import { client, GTM_ID } from "./config";
import { VendorProvider } from "./vendorContext";
import { getURLParameter, loadJSorCSSfile, emitGAEvent } from "./utils";
import SubmittalReceived from "./form/SubmittalReceived";
import Confirmed from "./form/Confirmed";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import ProcessingScreen from "./form/ProcessingScreen";
import SubmittalFail from "./form/SubmitFail";
import DeclinePrequal from "./form/DeclinePrequal";
import AnvilIframeOCAv3 from "./form/AnvilIframeOCAv3";
import PSFail from "./payment-selector/PSError";
import AppDedupe from "./form/AppDedupe";
import PSAccepted from "./payment-selector/PSAccepted";
import PostError from "./payment-selector/PostError";
import queryPublicAccount from "./api/queryPublicAccount";
import DLUpload from "./form/DLUploadPage";
import queryPublicAccountId from "./api/queryPublicAccountId";
import queryGetContact from "./api/queryGetContact";
import LanguageProvider from "./components/LanguageProvider";
//import { datadogLogs } from '@datadog/browser-logs';
import Declined from "./form/Declined";
import APISubmissionError from "./form/APISubmissionError";
import LenderPSConfirmed from "./payment-selector/LenderPSConfirmed";
import ClickLeaseBank from "./payment-selector/ClickLeaseBank";
import BankUploadError from "./payment-selector/BankUploadError";
import InvoiceTaskPage from "./form/InvoiceTaskPage";
import ApplicantsTaskPage from "./form/ApplicantsTaskPage";
import queryPartner from "./api/queryPartner";
import queryPartnerLink from "./api/queryPartnerLink";
import ContactForm from "./form/ContactFormPartner";
import NoVendorPage from "./pages/noVendorPage";
import { ChatWithDealer } from "./form/components/ChatWithDealer/ChatWithDealer";
import { getContrastColor } from "./utils/colorUtils";
import TagManager from "react-gtm-module";
const _ = require("lodash");

const tagManagerArgs = {
  gtmId: GTM_ID,
};
TagManager.initialize(tagManagerArgs);

// Datadog Real User Monitoring (RUM) enables you to visualize and analyze the real-time performance
// and user journeys of your application's individual users.
// if (DD_ENV == 'production') {
//   datadogRum.init({
//     applicationId: '898d59cb-0536-4e93-9ced-769d742b8ac3',
//     clientToken: 'pubc46a2f1b88c1cdc0c15beeb3703ea446',
//     site: 'datadoghq.com',
//     service: 'credit-app-v2',
//     env: DD_ENV,
//     //  version: '1.0.0',
//     sampleRate: 100,
//     trackInteractions: true,
//   });

//   datadogLogs.init({
//     clientToken: 'pubc46a2f1b88c1cdc0c15beeb3703ea446',
//     site: 'datadoghq.com',
//     service: 'credit-app-v2',
//     env: DD_ENV_LOGS,
//     forwardErrorsToLogs: true,
//     sampleRate: 100,
//   });
// }
const useStyles = makeStyles(theme => ({
  hideMainContent: {
    opacity: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function App() {
  const [vendorObj, setVendorObj] = useState({});

  const [repData, setRepData] = useState({
    cellPhone: "",
    email: "",
    mugshot: "avatar-placeholder.png",
    name: "",
    officePhone: "",
    availability: "",
  });
  const [loadingScreen, setLoadingScreen] = useState(true);

  let locations;

  const setVendorByContact = (vendorId, vendorProfile) => {
    let vendorObj = {};
    if (vendorProfile) {
      const currentLocationColorSchema = getCurrentLocationColorSchema(locations);
      const colorSchemaToUse = currentLocationColorSchema || vendorProfile.styles;
      vendorObj = {
        ...vendorProfile,
        color: colorSchemaToUse.color,
        secColor: colorSchemaToUse.secColor ? colorSchemaToUse.secColor : colorSchemaToUse.color,
        textColor: colorSchemaToUse.textColor ? colorSchemaToUse.textColor : "#ffffff",
      };
    }
    setVendorObj(vendorObj);
  };
  const vendorGUID = getURLParameter("vendorGUID");
  const vendorContactId = getURLParameter("vendorcontactId");
  const partnerId = getURLParameter("partnerId");
  const partnerDealerId = getURLParameter("partnerDealerId");
  const locationId = getURLParameter("locationId");

  const getCurrentLocationColorSchema = locations => {
    if (!locationId) {
      return;
    }
    const currentLocation = _.find(locations, { locationId: locationId });
    return currentLocation.colorSchema;
  };

  useEffect(() => {
    const repId = getURLParameter("repId");
    if (repId === "5e2e05a9-3873-e911-8108-005056b05a0f" || repId === "5b1ed757-3b5d-e911-8108-005056b05a0f" || repId === "280aae84-7e84-e211-95fd-005056a20000") {
      setVendorObj(vendorData["MSBC"]);
      setLoadingScreen(false);
    } else {
      setVendorObj(vendorData["default"]);

      if (vendorGUID) {
        queryPublicAccount(vendorGUID.toLowerCase()).then(response => {
          let vendorName = _.get(response, "data.data.publicAccount.name", "");
          //Take the user to the "no vendor when the API cannot find the vendor"
          if (!vendorName) {
            window.top.location = "/no-vendor";
          }
          if (vendorName != "") {
            emitGAEvent("account_loaded", { account_name: vendorName });
          } else {
            emitGAEvent("account_loaded", {
              account_name: "Empty Account Name",
            });
          }

          let vendorObj = {
            ...response.data.data.publicAccount.vendorProfile,
          };
          if (!!vendorObj.ocaForm) {
            if (vendorObj.ocaForm === "Dynamic v4" && (window.location.pathname.includes("/oca") || window.location.pathname === "/")) {
              const ocaV4URL = `/credit-app${window.location.search}`;

              window.top.location = ocaV4URL;
              return null;
            }
          }

          if (window.location.pathname.includes("/customer-task")) {
            const ocaV4TaskPage = `/task-page${window.location.search}`;
            window.top.location = ocaV4TaskPage;
            return null;
          }

          const vpStyles = _.get(response, "data.data.publicAccount.vendorProfile.styles");
          locations = _.get(response, "data.data.publicAccount.locations", []);
          const currentLocationColorSchema = getCurrentLocationColorSchema(locations);
          const colorSchemaToUse = currentLocationColorSchema || vpStyles;
          if (colorSchemaToUse) {
            const color = colorSchemaToUse.color;
            const panelTextColor = colorSchemaToUse.panelTextColor;

            vendorObj = {
              ...vendorObj,
              color,
              accountName: response.data.data.publicAccount.name,
              secColor: colorSchemaToUse.secColor ? colorSchemaToUse.secColor : colorSchemaToUse.color,
              textColor: colorSchemaToUse.textColor ? colorSchemaToUse.textColor : "#ffffff",
              panelTextColor: panelTextColor ? panelTextColor : getContrastColor(color),
            };
            setVendorObj(vendorObj);
            setLoadingScreen(false);
          } else {
            vendorObj = {
              color: "#1473e6",
              secColor: "#1473e6",
              textColor: "#ffffff",
            };
            setVendorObj(vendorObj);
            setLoadingScreen(false);
          }
        });
      }

      if (vendorContactId) {
        queryGetContact(vendorContactId.toLowerCase()).then(response => {
          let vendorName = _.get(response, "data.data.publicAccount.name", "");
          if (!vendorName) {
            window.top.location = "/no-vendor";
          }
          if (vendorName != "") {
            emitGAEvent("account_loaded", { account_name: vendorName });
          } else {
            emitGAEvent("account_loaded", {
              account_name: "Empty Account Name",
            });
          }

          let vendorObj = {
            ...response.data.data.publicAccount.vendorProfile,
          };
          if (!!vendorObj.ocaForm) {
            if (vendorObj.ocaForm === "Dynamic v4" && (window.location.pathname.includes("/oca") || window.location.pathname === "/")) {
              const ocaV4URL = `/credit-app${window.location.search}`;

              window.top.location = ocaV4URL;
              return null;
            }
          }

          const vpStyles = _.get(response, "data.data.publicAccount.vendorProfile.styles");
          locations = _.get(response, "data.data.publicAccount.locations", []);
          const currentLocationColorSchema = getCurrentLocationColorSchema(locations);
          const colorSchemaToUse = currentLocationColorSchema || vpStyles;
          if (colorSchemaToUse) {
            vendorObj = {
              ...vendorObj,
              accountName: response.data.data.publicAccount.name,
              color: colorSchemaToUse.color,
              secColor: colorSchemaToUse.secColor ? colorSchemaToUse.secColor : colorSchemaToUse.color,
              textColor: colorSchemaToUse.secColor ? colorSchemaToUse.secColor : "#ffffff",
            };
            setVendorObj({ ...vendorObj });
            setLoadingScreen(false);
          } else {
            vendorObj = {
              color: "#1473e6",
              secColor: "#1473e6",
              textColor: "#ffffff",
            };
            setVendorObj({ ...vendorObj });
            setLoadingScreen(false);
          }
        });
      }
      if (partnerId) {
        queryPartner(partnerId.toLowerCase()).then(response => {
          if (_.get(response, "data.data.partner")) {
            const partnerObj = {
              ...response.data.data.partner,
              partnerName: response.data.data.partner.name,
              partnerLogo: response.data.data.partner.partnerLogo,
            };
            if (_.get(response, "data.data.partner.styles")) {
              partnerObj.color = response.data.data.partner.styles.color;
              partnerObj.secColor = response.data.data.partner.styles.secColor ? response.data.data.partner.styles.secColor : response.data.data.partner.styles.color;
              partnerObj.textColor = response.data.data.partner.styles.secColor ? response.data.data.partner.styles.secColor : "#ffffff";
            }
            queryPartnerLink(partnerDealerId).then(response => {
              if (_.get(response, "data.data.publicAccount.vendorProfile.styles")) {
                const partnerLinkObj = {
                  ...partnerObj,
                  ...response.data.data.publicAccount.vendorProfile,
                  accountName: response.data.data.publicAccount.name,
                  vpId: response.data.data.publicAccount.vendorProfile.id,
                };
                setVendorObj({ ...partnerLinkObj });
                setLoadingScreen(false);
              } else {
                const vendorObj = {
                  ...partnerObj,
                };
                setVendorObj({ ...vendorObj });
                setLoadingScreen(false);
              }
            });
            setVendorObj({ ...partnerObj });
          } else {
            const vendorObj = {
              color: "#1473e6",
              secColor: "#1473e6",
              textColor: "#ffffff",
            };
            setVendorObj({ ...vendorObj });
            setLoadingScreen(false);
          }
        });
      }

      if (!vendorContactId && !vendorGUID && !partnerId) {
        emitGAEvent("account_loaded", {
          account_name: "No Account Name Specified",
        });
        setLoadingScreen(false);
      }
    }
  }, []);

  const theme = createMuiTheme({
    palette: {
      primary: { main: vendorObj.color ? vendorObj.color : "#1473e6" },
    },
  });

  const classes = useStyles(theme);

  return (
    <ApolloProvider client={client}>
      <Helmet>
        <script type="text/javascript" defer="true" src="//cdn.mouseflow.com/projects/aa624a5b-863f-4e42-9ae0-a5f1fc92f2f4.js" />
      </Helmet>
      <Backdrop className={classes.backdrop} open={loadingScreen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <VendorProvider value={{ ...vendorObj }}>
        <ThemeProvider theme={theme}>
          <div className={`${loadingScreen ? classes.hideMainContent : ""}`}>
            <header className="App-header">
              <Router>
                <Route path="/chatWithDealer">
                  <ChatWithDealer open={true} />
                </Route>
                <LanguageProvider vendorProfile={vendorObj} partnerId={partnerId}>
                  <MainLayout repData={repData} setRepData={setRepData}>
                    <Route
                      exact
                      path={["/", "/oca"]}
                      render={() => {
                        if (!vendorContactId && !vendorGUID && !partnerId) {
                          return <Redirect to="/no-vendor" />;
                        }
                        return (
                          <CreditApplication
                            vendorProfile={vendorObj}
                            setVendor={setVendorByContact}
                            industry={vendorObj.industryGroupType}
                            form={vendorObj.ocaForm}
                            setDemo=""
                            setRepData={setRepData}
                            partnerId={partnerId}
                            repData={repData}
                          />
                        );
                      }}
                    />
                    <Route path="/ps">
                      <PaymentSelector setRepData={setRepData} setVendorObj={setVendorByContact} />
                    </Route>
                    <Route path="/processing">
                      <ProcessingScreen />
                    </Route>
                    <Route path="/receipt">
                      <SubmittalReceived vendorProfile={vendorObj} />
                    </Route>
                    <Route path="/error">
                      <SubmittalFail />
                    </Route>
                    <Route path="/pserror">
                      <PSFail />
                    </Route>
                    <Route path="/posterror">
                      <PostError />
                    </Route>
                    <Route path="/pass">
                      <DeclinePrequal />
                    </Route>
                    <Route path="/confirmed">
                      <Confirmed />
                    </Route>
                    <Route path="/dedupe">
                      <AppDedupe />
                    </Route>
                    <Route path="/psconfirmed">
                      <PSAccepted />
                    </Route>
                    <Route path="/dlupload">
                      <DLUpload />
                    </Route>
                    <Route path="/demo">
                      <CreditApplication
                        vendorProfile={vendorObj}
                        setVendor={setVendorByContact}
                        industry={vendorObj.industryGroupType}
                        form={vendorObj.ocaForm}
                        setDemo="standard"
                        setRepData={setRepData}
                        partnerId={partnerId}
                        repData={repData}
                      />
                    </Route>
                    <Route path="/demoeq">
                      <CreditApplication
                        vendorProfile={vendorObj}
                        setVendor={setVendorByContact}
                        industry={vendorObj.industryGroupType}
                        form={vendorObj.ocaForm}
                        setDemo="demoeq"
                        setRepData={setRepData}
                        partnerId={partnerId}
                        repData={repData}
                      />
                    </Route>
                    <Route path="/demoex">
                      <CreditApplication
                        vendorProfile={vendorObj}
                        setVendor={setVendorByContact}
                        industry={vendorObj.industryGroupType}
                        form={vendorObj.ocaForm}
                        setDemo="demoex"
                        setRepData={setRepData}
                        partnerId={partnerId}
                        repData={repData}
                      />
                    </Route>
                    <Route exact path="/demo_oca_v3">
                      <AnvilIframeOCAv3 />
                    </Route>
                    <Route exact path="/democonsumer">
                      <CreditApplication
                        vendorProfile={vendorObj}
                        setVendor={setVendorByContact}
                        industry={vendorObj.industryGroupType}
                        form={vendorObj.ocaForm}
                        setDemo="democonsumer"
                        setRepData={setRepData}
                        partnerId={partnerId}
                        repData={repData}
                      />
                    </Route>
                    <Route path="/declined">
                      <Declined />
                    </Route>
                    <Route path="/apierr">
                      <APISubmissionError />
                    </Route>
                    <Route path="/lenderConfirmed">
                      <LenderPSConfirmed />
                    </Route>
                    <Route path="/bankUpload">
                      <ClickLeaseBank />
                    </Route>
                    <Route path="/bankUploadError">
                      <BankUploadError />
                    </Route>
                    <Route path="/task">
                      <InvoiceTaskPage />
                    </Route>
                    <Route path="/customer-task">
                      <ApplicantsTaskPage />
                    </Route>
                    <Route path="/contact">
                      <ContactForm />
                    </Route>
                    <Route path="/no-vendor">
                      <NoVendorPage theme={theme} />
                    </Route>
                  </MainLayout>
                </LanguageProvider>
              </Router>
            </header>
          </div>
        </ThemeProvider>
      </VendorProvider>
    </ApolloProvider>
  );
}

export default App;

const vendorData = {
  MSBC: {
    logo: "main-street.png",
    color: "#0071BD",
  },
  default: {
    color: "#1473e6",
    repdId: "2067558D-EE75-E011-94BF-005056A20000",
  },
};
